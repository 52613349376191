import React from 'react'

const FormStep1 = (props) => {
    const {inputValues, fieldErrors} = props

    return (
        <div id="formStep1">
            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="section-title slds-p-horizontal_small">
                        Please provide your information
                    </div>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Supplemental_Contact_First_Name__c" type="text" label="Your Name" placeholder="Your answer" required="true" ></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Supplemental_Contact_Last_Name__c" type="text" label="Your Last Name" placeholder="Your answer" required="true" ></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Supplemental_Contact_Title__c" type="text" label="Your Title" placeholder="Your answer" required="true" ></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input 
                                id="Supplemental_Contact_Email__c" 
                                type="text" 
                                label="Your Email" 
                                required="true" 
                                placeholder="Your answer" 
                                data-validate="email"
                                value={inputValues['Supplemental_Contact_Email__c']}
                                error-message={fieldErrors['Supplemental_Contact_Email__c']}>
                            </tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label"> 
                                <span className="u-required-simbol">*</span> 
                                Country of Residence
                            </div>
                            <tds-combobox 
                                id="Submitter_Contact_Country__c" 
                                data-list="country"
                                required="true" 
                                placeholder="Select country..."></tds-combobox>
                        </div>
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="section-title slds-p-horizontal_small">
                    Who signs off on program decisions and should be the main point of contact? Include your information if you fit this role
                    </div>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <tds-input 
                                id="CopyUserData"
                                label="Use same information as above"
                                type="checkbox" />
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Program_Lead_First_Name__c" type="text" label="Program Lead First Name" required="true" placeholder="Your answer"></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Program_Lead_Last_Name__c" type="text" label="Program Lead Last Name" required="true" placeholder="Your answer"></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Program_Lead_Title__c" type="text" label="Program Lead Title" required="true" placeholder="Your answer"></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input 
                                id="Program_Lead_Email__c" 
                                type="email" 
                                label="Program Lead Email" 
                                required="true" 
                                placeholder="Your answer" 
                                data-validate="email" 
                                value={inputValues['Program_Lead_Email__c']}
                                error-message={fieldErrors['Program_Lead_Email__c']}>
                            </tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <div className="u-input-label"> 
                                <span className="u-required-simbol">*</span> 
                                Country of Residence
                            </div>
                            <tds-combobox 
                                id="Primary_Contact_Country__c" 
                                data-list="country"
                                required="true" 
                                placeholder="Select country..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <div className="u-input-label"> 
                                &nbsp;
                            </div>
                            <tds-input 
                                id="Contact_Information_Consent__c"
                                label="If providing the contact information of others in your organization, you confirm that you have obtained consent to share the information with Salesforce from the individuals whose information you are providing."
                                type="checkbox"
                                required="true" />
                        </div>
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="section-title slds-p-horizontal_small">
                        Who should we contact for logos, and other marketing questions?
                    </div>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Marketing_Contact_First_Name__c" type="text" label="Marketing Contact First Name" placeholder="Your answer" value={inputValues['Marketing_Contact_First_Name__c']}></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Marketing_Contact_Last_Name__c" type="text" label="Marketing Contact Last Name" placeholder="Your answer" value={inputValues['Marketing_Contact_Last_Name__c']}></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Marketing_Contact_Title__c" type="text" label="Marketing Contact Title" placeholder="Your answer" value={inputValues['Marketing_Contact_Title__c']}></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input 
                                id="Marketing_Contact_Email__c" 
                                type="text" 
                                label="Marketing Contact Email" 
                                placeholder="Your answer" 
                                data-validate="email"
                                value={inputValues['Marketing_Contact_Email__c']}
                                error-message={fieldErrors['Marketing_Contact_Email__c']}>
                            </tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label"> 
                                Country of Residence
                            </div>
                            <tds-combobox 
                                id="Marketting_Contact_Country__c" 
                                data-list="country"
                                placeholder="Select country..."></tds-combobox>
                        </div>
                        
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="section-title slds-p-horizontal_small">
                        Do you have a current or previous point of contact at Salesforce?
                    </div>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Current_or_Previous_Salesforce_Contact__c_Name" type="text" label="Previous Contact Complete Name" placeholder="Your answer" value={inputValues['Current_or_Previous_Salesforce_Contact__c_Name']}></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Current_or_Previous_Salesforce_Contact__c_Title" type="text" label="Previous Contact Complete Title" placeholder="Your answer" value={inputValues['Current_or_Previous_Salesforce_Contact__c_Title']}></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Current_or_Previous_Salesforce_Contact__c_Team" type="text" label="Previous Contact Team" placeholder="Your answer" value={inputValues['Current_or_Previous_Salesforce_Contact__c_Team']}></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input 
                                id="Current_or_Previous_Salesforce_Contact__c_Email" 
                                type="text" 
                                label="Previous Contact Email" 
                                placeholder="Your answer" 
                                data-validate="email"
                                value={inputValues['Current_or_Previous_Salesforce_Contact__c_Email']}
                                error-message={fieldErrors['Current_or_Previous_Salesforce_Contact__c_Email']}>
                            </tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label"> 
                                Country of Residence
                            </div>
                            <tds-combobox 
                                data-list="country"
                                id="Current_or_Previous_Salesforce_Contact__c_Country" 
                                placeholder="Select country..."></tds-combobox>
                        </div>
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="section-title slds-p-horizontal_small">Organization Information</div>
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Organization_Name__c" type="text" label="Organization Name" required="true" placeholder="Your answer"></tds-input>
                        </div>
                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                <span>Organization Website Link</span>
                            </div>

                            <tds-input 
                                id="Organization_Website_Link__c" 
                                type="text" 
                                required="true" 
                                placeholder="URL" 
                                data-validate="url"
                                value={inputValues['Organization_Website_Link__c']}
                                error-message={fieldErrors['Organization_Website_Link__c']}></tds-input>
                            
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Make sure the URL includes the protocol like: "http://" or "https://"
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-horizontal_small">Registered Organization Physical Address</div>

                        {
                            // All the Address fields except country should be merged into one field "Registered_Organization_Address__c" when send the form to DRM
                        }

                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Registered_Organization_Address_street" type="text" label="Street Address" placeholder="Your answer"></tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Registered_Organization_Address_city" type="text" label="City" placeholder="Your answer"></tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <tds-input id="Registered_Organization_Address_state" type="text" label="State/Province" placeholder="Your answer"></tds-input>
                        </div>


                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <div className="u-input-label"> 
                                <span className="u-required-simbol">*</span> 
                                Country
                            </div>
                            <tds-combobox 
                                id="Registered_Organization_Country__c"
                                data-list="country"
                                required="true" 
                                placeholder="Select country..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-around_small">
                            <div className="u-input-label">
                                Are you a current Salesforce Partner or customer?
                            </div>
                            <tds-radio-group 
                                id="Current_Salesforce_Partner_or_customer__c" 
                                value={inputValues.Current_Salesforce_Partner_or_customer__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <tds-combobox 
                                id="Type_of_Organization__c" 
                                required="true" 
                                label="Type of organization" 
                                placeholder="Select the characterization that best describes your organization"></tds-combobox>
                        </div>
                    </div>
                </tds-card>
            </div>
        </div>
    )

}

export default FormStep1
